.paint-app-container {
  flex-grow: 1; /* Ensure it takes up available space */
  padding: 5%;
  overflow: hidden; /* Hide overflow */
  display: flex; /* Use flex to center the iframe */
  justify-content: center;
  align-items: center;
}

.paint-app-iframe {
  width: 100%;
  height: calc(100vh - 50px - 100px - 50px); /* Example with actual values */
  border: none;
}

