.footer {
  position: relative;
  width: 100%;
}

/* const colors = ["#9945FF", "#14F195", "#4ca4c4", "#7770e5"]; */

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.footer .country, .footer-links {
  /* Creating a gradient background using the Google colors */
  background: linear-gradient(270deg, #4285F4, #EA4335, #FBBC05, #34A853);
  background-size: 800% 800%;

  /* Applying the animation */
  animation: gradientAnimation 15s ease infinite;
}

.footer .country {
  padding: 15px 40px;
  color: #000000;
  font-size: 15px;
}

.footer-links {
  border-top: 1px solid #000000;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}

.footer-links-section {
  display: flex;
  align-items: center;
}

.footer-links-section a,
.footer-links-section button {
  text-decoration: none;
  color: #000000;
  padding: 0 18px;
  border: none;
  background: none;
  cursor: pointer;
}
.footer-links-section button {
  font-size: 14.5px;
}

.footer-links-section a:hover,
.footer-links-section button:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .footer .country {
    padding: 10px 20px;
    display: flex;
    justify-items: center;
  }

  .footer .country p {
    font-size: 16px;
  }
  .footer-links {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .footer-links-section {
    padding: 10px 0;
  }

  .footer-links-section a,
  .footer-links-section button {
    font-size: 13px;
  }
}


