body {
  margin: 0;
  width: 100%;
}

.all-results-container {
  margin-left: 180px;
  max-width: 650px;
  margin-bottom: 20px;
  min-height: 80vh;
}

@media screen and (max-width: 768px) {
  .filter-menu-items {
    margin-left: 0;
  }

  .filter-menu-item .icon {
    display: none;
  }
  body {
    overflow-x: hidden;
  }
}

.search-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.btn-row {
  display: flex;
  gap: 10px;
  width: 100%;
}

.search-btn {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 16px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  border-radius: 4px;
  color: #3c4043;
  cursor: pointer;
  user-select: none;
}

.search-btn:hover {
  box-shadow: 0 1px 1px rgba(0,0,0,.1);
  background-color: #f8f9fa;
  border: 1px solid #dadce0;
  color: #202124;
}

@media (max-width: 600px) {
  .btn-row {
    flex-direction: column;
  }
}
