.images-content {
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px; /* Increased gap for better spacing */
  justify-content: center; /* Center the content */
}

.images--card {
  width: 400px; /* Card width remains unchanged */
  height: auto; /* Allow height to adjust based on content */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.images--img-container {
  width: 220px; /* Increased width for larger images */
  height: 220px; /* Increased height to maintain square shape */
  overflow: hidden; /* Hide overflow */
}

.images--img-container:hover {
  box-shadow: 2px 2px 10px #ddd;
}

.images--img-container img {
  width: 100%; /* Make the image fill the container */
  height: 100%; /* Make the image fill the container */
  object-fit: cover; /* Ensure the image covers the square area */
}

.images--text-container {
  text-align: left;
  margin-left: 10px;
  color: #333;
  text-decoration: none;
  font-size: 15px;
}

.images--name {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.images--link {
  font-size: 14px;
  margin: 0;
  color: #777;
}

@media screen and (max-width: 810px) {
  .images--card {
    width: 320px; /* Adjusted for smaller screens */
  }
  .images-content {
    justify-content: center;
  }
}
